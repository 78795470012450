import React from "react";
import ProductExplore from "../../components/ProductExplore/ProductExplore";
// import ProductExploreCompo from "../../components/ProductExplore/ProductExploreV2";
import Hero from "../../components/Hero_section/Hero";
import Features from "../../components/Features/Features";
import Faq from "../../components/Faq/Faq"
import Coverflow from "../../components/HomePageSlider/StatisticsGrid";
import TestimonialV2 from "../Testimonials/TestimonialV2";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";

const Home = () => {
  return (
    <div className="home-page">
      <Hero />

      <Coverflow /> 

      <ProductExplore /> 

      {/* <ProductExploreCompo />  // This refracted code is ready to use only need to resolve styling issues. */}

      <Features />

      <Faq /> 

      <TestimonialV2 />
      <ScrollToTop />
    </div>
  );
};

export default Home;
