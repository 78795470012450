import React, { useState } from "react";
import Logo from "../Logo/Logo";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const [className, setClassName] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  if (
    location.pathname === "/signup" ||
    location.pathname === "/privacy-policy"
  ) {
    return null;
  }

  // Toggle the hamburger menu and side menu
  const sidePopup = () => {
    setClassName(!className);
    setMenuOpen(!menuOpen);
  }


  return (
    <div className="navbar-main-Container">
      <div className="navbar-content">
        <div className="navbar-logo">
          <Logo />
        </div>

        <div className="navbar-nav">
          <div className="nav-item">
            <Link to="/" className="nav-link" aria-current="page">
              Home
            </Link>
          </div>
          {/* <div className="nav-item">
            <a href="#productExplore" className="nav-link" >
              Feature
            </ a>
          </div> */}
          <div className="nav-item">
            <Link className="nav-link" aria-current="page" to="/about">
              About
            </Link>
          </div>
          <div className="nav-item">
            <Link className="nav-link" to="/contact">
              Contact
            </Link>
          </div>
        </div>
      </div>

      <div className={` ${className ? "navbar-options" : "navbar-options-for-mobile-view"}`}>
        <div className="navbar-account-section">
          <div className="nav-item">
            <Link to="/signup">
              <button class="navbar-button navbar-signup-button">
                Create a Free Account
              </button>
            </Link>
          </div>
          <div className="nav-item">
            {/* <Link to="http://saas.katalisia.com/"> */}
            <Link to="/login">
              <button class="navbar-button navbar-login-button">Login</button>
            </Link>
          </div>
        </div>
      </div>

      {/* Mobile screen */}
      <div onClick={sidePopup}
        className={`navbar-content-for-mobile-view ${className ? "" : "after-click"
          }`}
      >
        <div></div>
        <div></div>
        <div></div>
      </div>

      {/* Added Slide menu */}
      <div className={`mobile-slide-menu ${menuOpen ? "slide-in" : "slide-out"}`}>
        <div className="mobile-nav-item">
          <Link to="/" className="nav-link" onClick={sidePopup}>Home</Link>
        </div>
        <div className="mobile-nav-item">
          <Link to="/about" className="nav-link" onClick={sidePopup}>About</Link>
        </div>
        <div className="mobile-nav-item">
          <Link to="/contact" className="nav-link" onClick={sidePopup}>Contact</Link>
        </div>
        <div className="mobile-account-section">
          <Link to="/signup">
            <button className="mobile-signup-button">Sign Up</button>
          </Link>
          <Link to="/login">
            <button className="mobile-login-button">Login</button>
          </Link>
        </div>
      </div>
      {/* End */}

    </div>
  );
};

export default Navbar;
