import axios from "axios";
import { API_BASE_URL } from "../helpers/constant";
import Cookies from "js-cookie";

export default class AuthServices {
  // Signup service
  static signup(params) {
    return axios.post(`${API_BASE_URL}/public/user/signup`, params, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
  //Login services
  static async login(params) {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/public/user/login`,
        params,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      // Ensure the response structure is correct
      if (response.status === 200) {
        const { token, accountType } = response.data;

        // Store token in cookies (accessible across all subdomains)
        Cookies.set("authToken", token, {
          // for production use this

          domain: ".katalisia.com", // Ensures the token is available for all subdomains
          secure: false, // Use only if using HTTPS
          expires: 7, // Set expiry (7 days)

          // For local testing use this

          // domain: "localhost", // Cookie accessible across localhost subdomains
          // secure: false, // Disable secure flag for local development
          // expires: 7, // Cookie expiration
        });

        return { success: true, accountType }; // Return success and accountType
      }

      return { success: false, message: "Login failed" };
    } catch (error) {
      console.error(error);
      return {
        success: false,
        message: error.response?.data || "Login failed",
      };
    }
  }

  // Forgot Password service
  static forgotPassword(email) {
    return axios.post(
      `${API_BASE_URL}/public/user/forgot-password`,
      { email },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  // Reset Password service
  static resetPassword(params) {
    console.log("params in rest pasword", params);
    return axios.post(`${API_BASE_URL}/public/user/reset-password`, params, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}
