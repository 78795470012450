import { HashRouter, Routes, Route } from 'react-router-dom';
import MainLayout from './components/Layout/MainLayout';
import AuthLayout from './components/Layout/AuthLayout';
import Home from './Pages/Home/Home';
import ContactUs from './Pages/Contact/ContactUs';

import About from './Pages/About/About';
import Login from './Pages/Login/Login';
import ForgotPassword from './Pages/Login/ForgotPassword';
import { PrivacyPolicy } from './components/Privacy/PrivacyPolicy';
import SignUp from './Pages/Login/SignUp';
import ResetPassword from './Pages/Login/ResetPassword';

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/about" element={<About />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Route>

        <Route element={<AuthLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:tokenPath" element={<ResetPassword/>} />
        </Route>
      </Routes>
    </HashRouter>
  );
}

export default App;