import React, { useState } from "react";
import { Link} from "react-router-dom"; 
import UserFeedback from "../../components/userFeedback/userFeedback";
import "./ForgotPassword.css";
import Loader from "../../components/Loader/Loader";
import { setLoading } from "../../RtkStore/slices/loaderSlice";
import { useDispatch, useSelector } from "react-redux";
import AuthServices from "../../services/SignUpApi";

const ForgotPassword = () => {
  const loading = useSelector((state) => state.loader.loading);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    email: "",
  });

  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email)) {
      newErrors.email = "Invalid email format";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setLoading(true));

    try {
      if (validateForm()) {
        const response = await AuthServices.forgotPassword(formData.email);
        if (response.status === 200) {
          setMessage(
            "Password reset instructions have been sent to your email."
          );
        } else {
          setMessage("Failed to send reset instructions. Please try again.");
        }
      }
    } catch (error) {
      console.log(error.response?.data || error.message);
      setMessage("Failed to send reset instructions. Please try again.");
    } finally {
      dispatch(setLoading(false));
    }
  };

  // const handleSubmit = async (e) => {
  //     e.preventDefault();
  //     dispatch(setLoading(true));

  //     try {
  //         if (validateForm()) {
  //             await axios.post("https://an2sldwgo0.execute-api.ap-south-1.amazonaws.com/stage1/public/user/forgot-password", formData).then((res) => {
  //                 if (res.status === 200) {
  //                     setMessage("Password reset instructions have been sent to your email.");
  //                     dispatch(setLoading(false));

  //                     // Optionally, redirect to the login page after 5 seconds
  //                     // setTimeout(() => {
  //                     //     navigate("/login"); // Redirect to the login page
  //                     // }, 5000); // 5-second delay
  //                 }
  //             });
  //         }
  //     } catch (error) {
  //         dispatch(setLoading(false));
  //         console.log(error.response?.data || error.message);
  //         setMessage("Failed to send reset instructions. Please try again."); // Show error message
  //     } finally {
  //         dispatch(setLoading(false));
  //     }
  // };

  return (
    <>
      {loading && <Loader />}

      <div className="fp-wrapper">
        <div className="fp-form-container">
          <div className="formleft-side">
            <UserFeedback
              para1={
                "“Forgot your password? No worries! Enter your email address below, and we'll send you instructions to reset your password.”"
              }
              para2={
                "If you don't receive an email, please check your spam folder or contact our support team.”"
              }
            />
          </div>

          <div className="formright-side">
            <h2>Forgot Password</h2>
            <form onSubmit={handleSubmit} noValidate>
              <div className="input-container">
                <div className="form__group field">
                  <input
                    type="email"
                    className="form__field"
                    placeholder="Email"
                    name="email"
                    id="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    aria-describedby="email-error"
                    required
                    disabled={!!message} // Disable input after successful submission
                  />
                  <label htmlFor="email" className="form__label">
                    Email
                  </label>
                  {errors.email && (
                    <span id="email-error" className="error-message">
                      {errors.email}
                    </span>
                  )}
                </div>
              </div>

              <button
                type="submit"
                className="submit-button"
                disabled={!!message} 
              >
                Reset Password
              </button>
            </form>
            {<div>Hello</div>}

            {message && <p className="success-message">{message}</p>}

            <p className="sign-in-btn">
              Remember your password? <br />
              <Link to="/login">Login</Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
