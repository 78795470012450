import React, { useState } from "react";
import { Link } from "react-router-dom";
import UserFeedback from "../../components/userFeedback/userFeedback";
import "./Login.css";
import Loader from "../../components/Loader/Loader";
import { setLoading } from "../../RtkStore/slices/loaderSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import AuthServices from "../../services/SignUpApi";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import WarningIcon from "@mui/icons-material/Warning";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const loading = useSelector((state) => state.loader.loading);
  const dispatch = useDispatch();

  const [rememberMe, setRememberMe] = useState(
    localStorage.getItem("rememberMe") === "true"
  );

  const [formData, setFormData] = useState({
    email: rememberMe ? localStorage.getItem("rememberedEmail") || "" : "",
    password: rememberMe
      ? localStorage.getItem("rememberedPassword") || ""
      : "",
  });

  const [errors, setErrors] = useState({});
  const [loginError, setLoginError] = useState(""); // State for login error message

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setRememberMe(checked);

      if (!checked) {
        setFormData({ email: "", password: "" });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email)) {
      newErrors.email = "Invalid email format";
    }

    if (!formData.password) {
      newErrors.password = "Password is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setLoading(true));
    setLoginError(""); // Clear any previous error message

    try {
      if (validateForm()) {
        const response = await AuthServices.login(formData);

        if (response.success) {
          if (rememberMe) {
            localStorage.setItem("rememberedEmail", formData.email);
            localStorage.setItem("rememberedPassword", formData.password);
            localStorage.setItem("rememberMe", "true");
          } else {
            localStorage.removeItem("rememberedEmail");
            localStorage.removeItem("rememberedPassword");
            localStorage.removeItem("rememberMe");
          }

          // Extract accountType from the response
          const { accountType } = response;

          // Redirect based on accountType
          if (accountType === "COMPANY") {
            window.location.href = "http://company.katalisia.com/"; // Redirect to Influencer page
            // window.location.href = "http://localhost:3001"; // Redirect to local Company page
          } else {
            window.location.href = "http://saas.katalisia.com/"; // Redirect to Influencer page
            // window.location.href = "http://localhost:3002"; //Redirect to local Influencer page
          }
        } else {
          const errorMessage =
            response.message || "Login failed. Please check your credentials.";
          const parsedErrorMessage = errorMessage.replace(
            /{'error': '|'}/g,
            ""
          );
          setLoginError(parsedErrorMessage);
        }
      }
    } catch (error) {
      const errorMessage =
        error.response?.data || "Login failed. Please try again later.";
      const parsedErrorMessage = errorMessage.replace(/{'error': '|'}/g, "");
      setLoginError(parsedErrorMessage);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <>
      {loading && <Loader />}

      <div className="login-wrapper">
        <div className="login-form-container">
          <div className="formleft-side">
            <UserFeedback
              para1={
                "“Welcome back to Katalisia! We're glad to see you again. Please enter your email and password to access your account.”"
              }
              para2={
                "If you have any issues logging in, please contact our support team.”"
              }
            />
          </div>

          <div className="formright-side">
            <h3>Welcome Back!</h3>

            <form onSubmit={handleSubmit} noValidate>
              <div className="input-container">
                <div className="form__group field">
                  <input
                    type="email"
                    className="form__field"
                    placeholder="Email"
                    name="email"
                    id="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    aria-describedby="email-error"
                    required
                    autoComplete="off"
                  />
                  <label htmlFor="email" className="form__label">
                    Email
                  </label>
                  {errors.email && (
                    <span id="email-error" className="error-message">
                      {errors.email}
                    </span>
                  )}
                </div>

                <div className="form__group field">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form__field"
                    placeholder="Password"
                    name="password"
                    id="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    aria-describedby="password-error"
                    required
                    autoComplete="off"
                  />
                  <label htmlFor="password" className="form__label">
                    Password
                  </label>
                  {formData.password && (
                    <button
                      type="button"
                      className="password-mask"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <VisibilityOffOutlinedIcon size={20} />
                      ) : (
                        <VisibilityOutlinedIcon size={20} />
                      )}
                    </button>
                  )}
                  {errors.password && (
                    <span id="password-error" className="error-message">
                      {errors.password}
                    </span>
                  )}
                </div>
              </div>

              {loginError && (
                <div className="auth-error-message">
                  <WarningIcon className="error-icon"/>
                  <span className="error-text">{loginError}</span>
                </div>
              )}

              <button type="submit" className="submit-button">
                Login
              </button>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  width: "100%",
                  fontSize: "1rem",
                  paddingTop: "8px",
                }}
              >
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "black",
                    fontSize: "1rem",
                  }}
                >
                  <input
                    type="checkbox"
                    name="rememberMe"
                    style={{ marginRight: "8px" }}
                    checked={rememberMe}
                    onChange={handleInputChange}
                  />
                  Remember Me
                </label>
                <Link to="/forgot-password">Forgot Password?</Link>
              </div>
            </form>
            <p className="sign-in-btn">
              New to Katalisia? <br />
              <Link to="/signup">Sign Up</Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
