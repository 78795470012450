import { useState } from "react";
import company1 from "../../assets/images/companies c1.webp"
import company2 from "../../assets/images/companies c2.webp"
import company3 from "../../assets/images/companies c3.webp"
import content1 from "../../assets/images/content1.webp"
import content2 from "../../assets/images/content2.webp"
import content3 from "../../assets/images/content3.webp"
import partner1 from "../../assets/images/partner 1.webp"
import partner2 from "../../assets/images/partner 2.webp"
import partner3 from "../../assets/images/partner 3.webp"

let dataList = [
  {
    id: 0,
    data: [
      {
        heading: "Advanced Analytics",
        para: "Katalisia provides content creators with advanced analytics that offer in-depth insights into their audience and content performance. These insights help content creators make data-driven decisions to optimize their content strategy and increase their viewership.",
        img: content1,
      },
      {
        heading: "Revenue Optimization",
        para: "Katalisia helps content creators optimize their revenue by identifying potential sponsorship opportunities and providing recommendations on how to monetize their content better. The platform also helps creators track their earnings and provides insights into their revenue sources.",
        img: content2,
      },
      {
        heading: "Audience Growth",
        para: "Katalisia provides content creators with tools and insights to help them grow their audience. The platform offers recommendations on content optimization, audience targeting, and engagement strategies to increase their reach and attract new viewers.",
        img: content3,
      },
    ],
  },
  {
    id: 1,
    data: [
      {
        heading: "Detailed Analytics and Insights",
        para: "Katalisia provides companies with detailed analytics and insights on their social media campaigns, allowing them to measure their performance and make data-driven decisions.",
        img: company1,
      },
      {
        heading: "Targeted Influencer Search",
        para: "Katalisia provides a powerful search engine that allows companies to easily find influencers in their target niche. This makes it easy for businesses to connect with the right influencers to promote their products and services.",
        img: company2,
      },
      {
        heading: "Targeted Advertising",
        para: "Katalisia helps companies to target the right audience for their social media campaigns, ensuring that their ads are seen by the right people at the right time.",
        img: company3,
      },
    ],
  },
  {
    id: 2,
    data: [
      {
        heading: "Campaign Management",
        // para: "Katalisia streamlines campaign management for social media marketing agencies by providing a centralized platform to manage multiple campaigns across different social media channels. The platform offers tools to schedule posts, track engagement, and manage social media advertising campaigns, making it easier for agencies to oversee and manage their clients' social media presence.",
        para: "Katalisia streamlines campaign management for social media marketing agencies by providing a centralized platform to manage multiple campaigns across different social media channels. The platform offers tools to schedule posts and manage social media advertising campaigns...",
        img: partner1,
      },
      {
        heading: "Automated Reporting",
        para: "With Katalisia, social media marketing agencies can automate their reporting processes and save time on manual data collection and analysis. The software generates easy-to-understand reports that can be customized to meet the specific needs of each client.",
        img: partner2,
      },
      {
        heading: "Collaboration Tools",
        // para: "Katalisia provides social media marketing agencies with collaboration tools that enable them to work seamlessly with their clients. This includes features like shared calendars, task lists, and project management tools, which can help to streamline communication and ensure that all parties are on the same page.",
        para: "Katalisia provides social media marketing agencies with collaboration tools that enable them to work seamlessly with their clients. This includes features like shared calendars, task lists, and project management tools...",
        img: partner3,
      },
    ],
  },
];

const List = ({ data }) => {
  return (
    <>
      <div className="product-explore-content-options">
        <div className="product-explore-content-options-one">
          <div className="product-explore-content-options-container">
            <div>
              <img src={data[0].img} alt="img1" />
            </div>

            <div className="card-content">
              <h3>{data[0].heading}</h3>
              <span>{data[0].para}</span>

              <button>Learn More</button>
            </div>

          </div>
          <div className="product-explore-content-options-container">
            <div>
              <img src={data[1].img} alt="img2" />
            </div>

            <div className="card-content">
              <h3>{data[1].heading}</h3>
              <span>{data[1].para}</span>

              <button>Learn More</button>
            </div>

          </div>
          <div className="product-explore-content-options-container">
            <div>
              <img src={data[2].img} alt="img3" />
            </div>

            <div className="card-content">
              <h3>{data[2].heading}</h3>
              <span>{data[2].para}</span>
              <button>Learn More</button>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

const ProductExplore = () => {
  const [state, setState] = useState(0);
  return (
    <div className="product-explore-main-container" id="productExplore">
      <div className="product-explore-heading-section">
        <button>Product Overview</button>
        <h1>Explore Katalisia’s Powerful Tools</h1>

        <h4>
          Unlock advanced analytics, optimize revenue, and drive audience growth. Whether you're a content creator, company, or digital agency, Katalisia provides the tools and insights you need to elevate your digital strategy and achieve success.
        </h4>
      </div>
      <div className="product-explore-content">
        <div className="product-explore-content-heading">
          <div
            className={`product-explore-content-heading one ${state === 0 ? "selected" : ""
              }`}
            onClick={() => {
              setState(0);
            }}
          >
            Content Creators
          </div>
          <div
            className={`product-explore-content-heading two ${state === 1 ? "selected" : ""
              }`}
            onClick={() => {
              setState(1);
            }}
          >
            Companies
          </div>
          <div
            className={`product-explore-content-heading three ${state === 2 ? "selected" : ""
              }`}
            onClick={() => {
              setState(2);
            }}
          >
            Digital Agencies
          </div>
        </div>

        <List data={dataList[state].data} img={dataList[state].img} />
      </div>
    </div>
  );
};

export default ProductExplore;
