import { NavLink } from "react-router-dom";
import logo from "../../assets/images/logo.png";

const Logo = () => {
  return (
    <NavLink className="logo" to="/" >   
      <img className="logo-image" src={logo} alt="logo" />
      <div className="logo-title">Katalisia</div>
    </NavLink>
  );
};

export default Logo;
